import type { ResolvedControl } from '../contracts/control.js';
import type { MissedVisit, VisitTime } from './types.js';

export const linearMatch = (
  visitTimes: VisitTime[],
  controls: ResolvedControl[],
  skippedFirstControls: number | undefined | null,
): (VisitTime | MissedVisit)[] => {
  const visits: (VisitTime | MissedVisit)[] = [];

  // ignore start visits after the two other control visits
  const visitTimesFiltered = visitTimes.filter(
    (visit, index) =>
      index < 2 ||
      (visit.control.type ??
        controls.find((control) => control._id === visit.control._id)?.type) !==
        'start',
  );

  let next = 0;
  controls.forEach((control, controlIndex) => {
    if (controlIndex < (skippedFirstControls ?? 0)) {
      visits.push({ control });
      return;
    }
    for (let i = next; i < visitTimesFiltered.length; i++) {
      const visit = visitTimesFiltered[i];
      if (visit.control._id === control._id) {
        let nearest = visit;
        for (; i < visitTimesFiltered.length; i++) {
          const nextVisit =
            i < visitTimesFiltered.length - 1
              ? visitTimesFiltered[i + 1]
              : undefined;
          if (!nextVisit || nextVisit.control._id !== control._id) {
            break;
          }
          if (nextVisit.nearestDistance <= nearest.nearestDistance) {
            nearest = nextVisit;
          }
        }
        next = i + 1;
        visits.push(control.type === 'finish' ? visit : nearest);
        return;
      }
    }
    visits.push({ control });
  });
  return visits;
};
